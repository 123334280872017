import Axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import router from "@/router";
import store from "@/store";
import { parseJwt } from "@/helpers/jwt";

export class ChatEndpoint {
    constructor(public id: string, public description: string = "") {}
}

export class ChatMessageRequest {
    constructor(
        public text: string,
        public output_self: boolean = false) {}
}

export class ChatIntentRequest {
    constructor(
        public name: string,
        public message_text?: string,
        public output_self: boolean = false) {}
}

export class ChatMessagesRequest {
    constructor(
        public count: number = 99999,
        public time_from?: number,
        public time_until?: number,
        public include_events: boolean = false) {}
}

export class ChatConversationInfo {
    constructor(
        public id: string,
        public is_archived: boolean) {}
}

export default class ChatbotApi {
    protected axios: AxiosInstance;
    protected endpoint?: ChatEndpoint;

    protected async ensureTokens() {
        const token = store.state.chatbotToken;
        const token_data = parseJwt(token!);
        if (token_data.exp <= (Date.now()/1000)) {
            return store.dispatch('refreshChatbotToken');
        }
        return Promise.resolve();
    }

    protected httpRequestHandler = (config: AxiosRequestConfig) => {
        return this.ensureTokens().then(() => {
            config.headers.Authorization = `Bearer ${store.state.chatbotToken}`;
            return config;
        });
    }
    protected httpResponseHandler = (response: any) => response;
    protected httpErrorHandler = (error: any) => Promise.reject(error);

    protected endpointPath(url: string) {
        if (!this.endpoint)
            throw new Error("Invalid endpoint");
        return `/${this.endpoint!.id}/${url.replace(/^\//, "")}`;
    }

    constructor(path: string) {
        this.axios = Axios.create({ baseURL: path });
        this.axios.interceptors.request.use(this.httpRequestHandler);
        this.axios.interceptors.response.use(this.httpResponseHandler, this.httpErrorHandler);
    }

    queryChatbots = () => this.axios.get<ChatEndpoint[]>("/chatbots/");
    selectEndpoint = (name: string) => new Promise<ChatEndpoint>((resolve, reject) => {
        this.queryChatbots().then(endpoints => {
            const endpoint = endpoints.data.find(e => e.id === name);
            if (endpoint) {
                this.endpoint = endpoint;
                resolve(endpoint);
            } else {
                throw new Error("Endpoint not found");
            }
        }, reject);
    });

    getConversations = () => this.axios.get<ChatConversationInfo[]>(this.endpointPath("/conversations/"));
    createConversation = () => this.axios.post<ChatConversationInfo>(this.endpointPath("/conversation/"))
    getOrCreateConversation = () => new Promise<ChatConversationInfo>((resolve, reject) => {
        this.getConversations().then((conversations) => {
            const conversation = conversations.data.find<ChatConversationInfo>((e): e is ChatConversationInfo => e.is_archived === false);
            if (conversation) {
                return resolve(conversation);
            }
            resolve(this.createConversation().then(response => response.data));
        }, reject);
    });
    getMessages = (conversation_id: string, params: ChatMessagesRequest) => 
            this.axios.get<any[]>(this.endpointPath(`/conversation/${conversation_id}/messages/`), {params});

    sendMessage = (conversation_id: string, message: ChatMessageRequest) => 
            this.axios.post<any[]>(this.endpointPath(`/conversation/${conversation_id}/message/`), message);
    
    sendIntent = (conversation_id: string, intent: ChatIntentRequest) =>
            this.axios.post<any[]>(this.endpointPath(`/conversation/${conversation_id}/intent/`), intent);

    sendVaguePayload(conversation_id: string, payload: string, title?: string) {
        if (payload.trim().startsWith('/'))
            return this.sendIntent(conversation_id, new ChatIntentRequest(payload.replace(/^\/+/, ""), title));
        return this.sendMessage(conversation_id, new ChatMessageRequest(payload));
    }
}
