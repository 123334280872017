
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import i18n from "@/i18n";
import { post } from "@/api";
import { User } from "@/interfaces/user";
import axios, { AxiosResponse } from "axios";
import ChatbotApi, {
  ChatIntentRequest,
  ChatMessageRequest,
  ChatMessagesRequest,
} from "@/api/chatbot_api";

const pageKey = "chatbot";

interface Message {
  author: string;
  text: string;
  buttons?: Button[];
  created: Date;
  type: string;
}

interface Button {
  payload: string;
  title: string;
}

@Component({
  components: {},
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class TherapyMessenger extends Vue {
  messages: Message[] = [];
  newMessage = "";
  api: ChatbotApi;
  conversationId = "";
  @Prop()
  lastBotMessage?: Message;

  constructor() {
    super();
    this.api = new ChatbotApi(process.env.VUE_APP_CHATBOT_BASE!);
  }

  get user() {
    const u = this.$store.state.user;
    return `${u?.first_name} ${u?.last_name}`;
  }

  updateLastBotMessageId() {
    for (let i = this.messages.length - 1; i >= 0; i--) {
      const msg = this.messages[i];
      if (msg.author === "Chatbot") {
        this.lastBotMessage = msg;
        return;
      }
    }
  }

  mounted() {
    this.api.selectEndpoint("general_bot").then(async (endpoint) => {
      this.conversationId = (await this.api.getOrCreateConversation()).id;
      const messages = await this.api.getMessages(
        this.conversationId,
        new ChatMessagesRequest()
      );
      messages.data.forEach((message: any) => {
        const messageObj = {
          author: "Chatbot",
          text: message.payload.text,
          created: new Date(message.payload.timestamp * 1000),
          type: "normal",
          buttons: message.payload.data?.buttons,
          id: message.id,
        };

        if (message.type === "user") {
          messageObj.author = this.user;
        }
        this.messages.push(messageObj);
      });
      const lastMessage = this.messages[this.messages.length - 1];
      if (
        lastMessage &&
        (new Date().getTime() - lastMessage.created.getTime()) / 1000 >= 5 * 60
      ) {
        const { data } = await this.api.sendIntent(
          this.conversationId,
          new ChatIntentRequest("EXTERNAL_welcome_back")
        );
        this.pushNewMessages(data);
      }
      this.scrollToLatest();
    }, alert);
  }

  async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  pushNewMessages(data: any[]) {
    this.messages.push(
      ...data.map((message: any) => ({
        author: "Chatbot",
        text: message.payload.text,
        buttons: message.payload.data?.buttons,
        created: new Date(),
        type: "normal",
      }))
    );
  }

  scrollToLatest() {
    this.updateLastBotMessageId();
    this.$nextTick(() => {
      (this.$refs.messages as HTMLDivElement).scrollTo(
        0,
        (this.$refs.messages as HTMLDivElement).scrollHeight
      );
    });
  }

  sendMessage(message: string) {
    return this.sendAction(
      message,
      this.api.sendMessage(this.conversationId, new ChatMessageRequest(message))
    );
  }

  sendButton(button: Button) {
    return this.sendAction(
      button.title,
      this.api.sendVaguePayload(
        this.conversationId,
        button.payload,
        button.title
      )
    );
  }

  async sendAction(message: string, action: Promise<AxiosResponse<any[]>>) {
    const startTime = new Date();
    if (!message.trim()) return;

    if (
      this.messages.length > 0 &&
      this.messages[this.messages.length - 1].type === "special"
    )
      this.messages.pop();

    this.scrollToLatest();

    this.messages.push({
      author: this.user,
      text: message,
      created: new Date(),
      type: "normal",
    });

    this.scrollToLatest();

    this.newMessage = "";

    const sleeper = () => {
      setTimeout(() => {
        if (this.messages[this.messages.length - 1].type !== "special") {
          this.messages.push({
            author: "",
            text: "",
            created: new Date(),
            type: "special",
          });
          this.scrollToLatest();
        }
      }, 500);
      return this.sleep(500);
    };

    const [{ data }] = await Promise.all([action, sleeper()]);

    const endTime = new Date();

    const timeDiff = endTime.getTime() - startTime.getTime();

    const letters = data.reduce((prev: any, current: any) => {
      return prev + current.payload.text.length;
    }, 0);

    const writingTime = Math.min(1000 + letters * 5, 2500);

    if (timeDiff < writingTime) {
      await this.sleep(writingTime - timeDiff);
    }

    if (this.messages.length > 0) this.messages.pop();

    this.pushNewMessages(data);

    this.scrollToLatest();
  }
}
